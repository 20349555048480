
import { computed, onMounted, PropType, Ref, ref, watch } from "vue";
import { ContentLoader } from 'vue-content-loader';
import { getModeloChecklistByID, getModelosChecklist } from '@/services/ModeloChecklistService';
import { hideModal } from "@/core/helpers/dom";
import { ElTable } from 'element-plus';

interface IOption {
  label: string;
  value: string;
}

interface IOptions {
  modelosChecklist: Array<IOption>
}
    
interface IPergunta {
  campo: string;
  pergunta: string;
  tipo: string;
}

interface IModeloChecklist {
  modeloFormatted: Array<{
    name: string;
    type: string;
    acceptNull: boolean;
    primaryKey: boolean;
    showInChecklistPerguntas: boolean;
    checklistPergunta: IPergunta;
  }>
}

interface IProps {
  id: String;
  resetModal: Boolean;
  optionsModeloChecklist: IOptions;
}

export default {
    name: "copiarPerguntasChecklistModal",
  
    props: {
      id: {
        type: String,
        required: true,
      },
      resetModal: {
        type: Boolean,
        required: true,
      },
      optionsModeloChecklist: {
        type: Array,
        required: true,
      }
    },

    emits: ['copiarModelo'],

    components: {
      ContentLoader,
    },

    setup(props, { emit }) {
        let animate = ref(false);
        
        const multipleTableRef = ref<InstanceType<typeof ElTable>>()

        const modeloChecklist: Ref<IModeloChecklist> = ref({
          modeloFormatted: []    
        });

        const form = ref({
          modeloChecklist: ""
        });

        const search = ref('')
        const filterTableData = computed(() =>
            modeloChecklist.value.modeloFormatted.filter((data) =>{
              if(data.name == 'codCheckList' || data.name == 'codConcessionaria' || data.name == 'checklist') return
              
              return !search.value ||
              data.name.toLowerCase().includes(search.value.toLowerCase()) ||
              data.type.toLowerCase().includes(search.value.toLowerCase())}
            )
        )

        watch(() => props.resetModal, () => {
          search.value = "";
        });
        
        async function listaModeloByID(id){
            animate.value = true;

            let response = await getModeloChecklistByID(id);

            modeloChecklist.value = response;
            animate.value = false;   
        }

        const multipleSelection = ref([])

        const handleSelectionChange = (val) => {
          multipleSelection.value = val
        }

        function emitirCopiarModelo(){
          emit("copiarModelo", multipleSelection.value);
          search.value = "";
          multipleTableRef.value!.clearSelection();
          closeModal();
        };

        function closeModal(){
          const modal = document.getElementById(`${props.id}_modal`);
          hideModal(modal);
        };
  
        return {
          animate,
          form,
          modeloChecklist,
          filterTableData,
          multipleTableRef,
          search,
          multipleSelection,
          handleSelectionChange,
          emitirCopiarModelo,
          listaModeloByID,
        }
    }
}
