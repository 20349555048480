
import {computed, onMounted, Ref, ref, watch} from "vue";
import {ContentLoader} from 'vue-content-loader';
import { getModeloChecklistByID, getModelosChecklist } from '@/services/ModeloChecklistService';
import { boolean } from 'yup/lib/locale';
import { hideModal } from "@/core/helpers/dom";

interface IOption {
  label: string;
  value: string;
}

interface IOptions {
  modelosChecklist: Array<IOption>
}
    
interface IPergunta {
  campo: string;
  pergunta: string;
  tipo: string;
}

interface IModeloChecklist {
  perguntas: Array<IPergunta>;
  checklist: Number;
  nome: String;
  tabela: String;
  diretorio: String;
  menus: String;
  arteorcamento: String;
  impressao: String;
  api: String;
  nomereal: String;
  formulario: String;
  temoficinadigital: Number;
  completo: Number;
  descricao: String;
  concessionarias: Array<String>
}

export default {
  name: "copiarBaseChecklistModal",
  emits: ['copiarModelo'],
  props: {
    id: {
      type: String,
      required: true,
    },
    resetModal: {
      type: Boolean,
      required: true,
    },
    optionsModeloChecklist: {
      type: Array,
      required: true,
    }
  },

  components: {
    ContentLoader,
  },
  
  setup(props, { emit }) {
    let animate = ref(false);
    
    const modeloChecklist: Ref<IModeloChecklist> = ref({
      perguntas: [],
      checklist: 0,
      nome: "",
      tabela: "",
      diretorio: "",
      arteorcamento: "",
      menus: "",
      impressao: "",
      api: "",
      nomereal: "",
      formulario: "",
      temoficinadigital: 0,
      completo: 0,
      descricao: "",
      concessionarias: []
    });

    const form = ref({
        modeloChecklist: ""
    });

    async function listaModeloByID(id){
      animate.value = true;

      let response = await getModeloChecklistByID(id);

      modeloChecklist.value = response;
      animate.value = false;   
    };
    
    function emitirCopiarModelo(){
      emit("copiarModelo", modeloChecklist.value);
      closeModal();
    };

    function closeModal(){
      const modal = document.getElementById(`${props.id}_modal`);
      hideModal(modal);
    };

    return {
      animate,
      form,
      modeloChecklist,
      listaModeloByID,
      emitirCopiarModelo,
    }
  }
}
