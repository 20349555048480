
  import {ref, watch} from "vue";
  import {ContentLoader} from 'vue-content-loader';
  import useAlert from "@/composables/Alert";
  import { hideModal } from "@/core/helpers/dom";

  export default {
    name: "visualizarStatusChecklistModal",
  
    props: {
      dadosModeloChecklist: {
        type: Object,
        required: true,
      },
      id: {
        type: String,
        required: true,
      }
    },
  
    components: {
      ContentLoader,
    },

    emits: ["add", "update"],
    setup(props, { emit }) {
      let animate = ref(false);
      let jsonText = ref("{}");
      const { showTimeAlert } = useAlert();

      const jsonEditor:any = ref(null);

      const options = {
        setorCor: [
          {
            label: "Warning",
            value: "warning"
          },
          {
            label: "Info",
            value: "info"
          },
          {
            label: "Primary",
            value: "primary"
          },
          {
            label: "Danger",
            value: "danger"
          },
          {
            label: "Success",
            value: "success"
          },
          {
            label: "Darkyellow",
            value: "darkyellow"
          },
          {
            label: "Green",
            value: "green"
          },
          {
            label: "End",
            value: "end"
          },
        ],

        acao: [
          {
            label: "Insert",
            value: "insert"
          },
          {
            label: "Update",
            value: "update"
          },
        ],
        acaoApi: [
          {
            label: "Post",
            value: "post"
          },
          {
            label: "Put",
            value: "put"
          },
        ],
        acesso: [
          {
            label: "0",
            value: "0"
          },
          {
            label: "1",
            value: "1"
          },
          {
            label: "2",
            value: "2"
          },
        ],
        jsonEditor: {
          mode: 'code',
          language: 'pt-BR'
        }
      };

      const defaultFormModeloStatusChecklist = {
        status: 0,
        setor: "",
        setorCor: "",
        link: "",
        linksalvar: "",
        linkproximo: "",
        nivel: 0,
        botao: "",
        acao: "",
        acaoApi: "",
        exibePdf: 0,
        enviaEmail: 0,
        header: 0,
        perguntaAplicavel: "",
        oficinaDigital: 0,
        inicioMecanico: 0,
        inicioOutros: 0,
        acesso: [],
        tituloPagina: "",
        informacaoAdicional: ""
      };

      const rules = ref({
        status: [
          {
            required: true,
            message: "Preencha o código status",
            trigger: "blur",
          },
        ],
        setor: [
          {
            required: true,
            message: "Preencha o setor do status",
            trigger: "blur",
          },
        ],
        setorCor: [
          {
            required: true,
            message: "Selecione a cor do setor",
            trigger: "blur",
          },
        ],
        nivel: [
          {
            required: true,
            message: "Preencha o nível",
            trigger: "blur",
          },
        ],
        exibePdf: [
          {
            required: true,
            message: "Selecione uma opção",
            trigger: "blur",
          },
        ],
        enviaEmail: [
          {
            required: true,
            message: "Selecione uma opção",
            trigger: "blur",
          },
        ],
        header: [
          {
            required: true,
            message: "Selecione uma opção",
            trigger: "blur",
          },
        ],
        oficinaDigital: [
          {
            required: true,
            message: "Selecione uma opção",
            trigger: "blur",
          },
        ],
        inicioMecanico: [
          {
            required: true,
            message: "Selecione uma opção",
            trigger: "blur",
          },
        ],
        inicioOutros: [
          {
            required: true,
            message: "Selecione uma opção",
            trigger: "blur",
          },
        ],
        acesso: [
          {
            required: true,
            message: "Selecione os acessos",
            trigger: "blur",
          },
        ],
        tituloPagina: [
          {
            required: true,
            message: "Preencha o título página",
            trigger: "blur",
          },
        ],
      });

      let formModeloStatusChecklist = ref({ ...defaultFormModeloStatusChecklist });

      const formStatusChecklistRef = ref<null | HTMLFormElement>(null);
  
      const consultorModal: any = ref({});
      watch(() => props.dadosModeloChecklist, () => {
        if(props.dadosModeloChecklist){
          const form = {
            ...props.dadosModeloChecklist, 
            acesso: props.dadosModeloChecklist.acesso.replaceAll(",", ";").split(";")
          }

          formModeloStatusChecklist.value = { ...form};
        } else {
          clearForm();
        }
      });

      function clearForm() {
        formModeloStatusChecklist.value = {
          status: 0,
          setor: "",
          setorCor: "",
          link: "",
          linksalvar: "",
          linkproximo: "",
          nivel: 0,
          botao: "",
          acao: "",
          acaoApi: "",
          exibePdf: 0,
          enviaEmail: 0,
          header: 0,
          perguntaAplicavel: "",
          oficinaDigital: 0,
          inicioMecanico: 0,
          inicioOutros: 0,
          acesso: [],
          tituloPagina: "",
          informacaoAdicional: ""
        };
      }

      function closeModal() {
        const modal = document.getElementById(`${props.id}_modal`);
        hideModal(modal);
        clearForm();
      };

      function save() {
        if (!formStatusChecklistRef.value) {
          return;
        }

        formStatusChecklistRef.value.validate((valid) => {
          if (valid) {
            const form = {
              ...formModeloStatusChecklist.value,
              acesso: formModeloStatusChecklist.value.acesso.sort().join(";")
            }

            if(props.dadosModeloChecklist){
              emit("update", form);
              showTimeAlert("O status foi editado com sucesso!");
            } else {
              emit("add", form);
              showTimeAlert("O status foi adicionado com sucesso!");
            }

            closeModal();
          }
        });
      }
  
      return {
        animate,
        consultorModal,
        formModeloStatusChecklist,
        options,
        jsonEditor,
        rules,
        formStatusChecklistRef,
        save
      }
    }
  }
